import React, { PureComponent } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import cn from './Navigation.module.scss';

// const { PUBLIC_URL } = process.env;

export class Navigation extends PureComponent {
  render() {
    return (
      <div className={cn.navigationWrapper}>
        <div className={cn.navigationContainer}>
          <p className={cn.stylist}>Cassie Carter</p>
          {/* <img
            src={`${PUBLIC_URL}/rss-mark.svg`}
            height="44"
            width="44"
            alt="Cassie Carter"
          /> */}
          <p className={cn.navContact}>(425) 314-6901</p>
          <div className={cn.flexGrow} />
          <AnchorLink className={cn.navigationLink} href="#services">
            Services
          </AnchorLink>
          <AnchorLink className={cn.navigationLink} href="#stylist">
            Stylists
          </AnchorLink>
          <AnchorLink className={cn.navigationLink} href="#gallery">
            Gallery
          </AnchorLink>
          <AnchorLink className={cn.navigationLink} href="#location">
            Location
          </AnchorLink>
        </div>
      </div>
    );
  }
}
